<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Territories">

                <b-button v-if="$hasPermission($permissions.AddAreas)" variant="primary" @click="addTerritoryModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="formattedData" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditAreas)" variant="warning" @click="openEdit(territories[props.row.index])" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveAreas)" variant="danger" @click="removeTerritory(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: props.formattedRow[props.column.field] }"></div>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>

            <b-modal title="Add territory" v-model="addTerritoryModalActive" no-close-on-backdrop>
                <template #default>

                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="addObject.name"/>
                    </b-form-group>

                    <ColorPicker v-model="addObject.color"/>

                    <b-form-group>
                        <label>Areas</label>
                        <v-select
                            v-model="addObject.areas"
                            :reduce="area => area.id"
                            label="name"
                            multiple
                            :options="areas"
                        />
                    </b-form-group>
                </template>

                <template #modal-footer>

                    <b-button variant="danger" @click="addTerritoryModalActive = false">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Close</span>
                    </b-button>

                    <b-button variant="primary" @click="addTerritory">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add</span>
                    </b-button>
                </template>
            </b-modal>

            <b-modal title="Edit territory" v-model="editTerritoryModalActive" no-close-on-backdrop>
                <template #default>
                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="editObject.name"/>
                    </b-form-group>

                    <ColorPicker v-model="editObject.color"/>

                    <b-form-group>
                        <label>Areas</label>
                        <v-select
                            v-model="editObject.areas"
                            :reduce="area => area.id"
                            label="name"
                            multiple
                            :options="areas"
                        />
                    </b-form-group>
                </template>

                <template #modal-footer>

                    <b-button variant="danger" @click="editTerritoryModalActive = false">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Close</span>
                    </b-button>

                    <b-button variant="warning" @click="saveTerritory">
                        <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Save</span>
                    </b-button>
                </template>
            </b-modal>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BOverlay, BModal} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'
    import ColorPicker from '@/views/components/ColorPicker'

    export default {
        components: {
            ColorPicker,
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            vSelect,
            BFormGroup,
            BFormInput,
            BModal
        },
        data() {
            return {
                dataLoaded: false,
                territories: [],

                areas: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'District',
                        displayType: 0,
                        field: 'district',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Number of areas',
                        displayType: 0,
                        field: 'areas',
                        sortFn: (x, y) => ((Number(x) < Number(y)) ? -1 : (Number(x) > Number(y)) ? 1 : 0),
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Color',
                        displayType: 2,
                        field: 'color',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addTerritoryModalActive: false,
                addObject: {
                    name: '',
                    color: '#000000',
                    areas: []
                },

                editTerritoryModalActive: false,
                editObject: {
                    name: '',
                    color: '#000000',
                    areas: []
                }
            }
        },
        methods: {
            openEdit(territory) {
                this.editObject = JSON.parse(JSON.stringify(territory))
                this.editTerritoryModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/territory')
                loadPromise.then(function(response) {
                    thisIns.territories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const areaLoadPromise = this.$http.get('/api/management/v1/area')
                areaLoadPromise.then(function(response) {
                    thisIns.areas = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, areaLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addTerritory() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/territory', this.addObject)
                addPromise.then(function() {
                    thisIns.addTerritoryModalActive = false
                    thisIns.$printSuccess('Territory added')
                    thisIns.loadData()
                    thisIns.addObject = {name: '', color: '#000000', areas: []}
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            saveTerritory() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/territory/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.editTerritoryModalActive = false
                    thisIns.$printSuccess('Territory saved')
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            async removeTerritory(id) {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this territory?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/territory/${  id}`)
                    this.$printSuccess('Territory removed')
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                } finally {
                    this.loadData()
                }
                
            }
        },
        computed: {
            formattedData() {
                return this.territories.map((territory, index) => {
                    return {
                        index,
                        id: territory.id,
                        name: territory.name,
                        district: (territory.district_id !== this.nullObjectId) ? territory.district : 'Not defined',
                        areas: String(territory.areas.length),
                        color: territory.color
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>